import React from "react";
import Layout from "components/layouts/Simple";
import classNames from "classnames";
import { Button, NoSsr } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/mainPage.js";
import Section from "components/pages/front/Sections/Section";
const useStyles = makeStyles(styles);

const NotFoundPage = () => {
  const classes = useStyles();
  return (
    <NoSsr>
      <Layout>
        <Section>
          <h1>ページがみつかりません。</h1>
          <p>
            申し訳ありませんが、お探しのページは存在しないか、閲覧できなくなっております。
          </p>

          <div>
            <Button variant="contained" color="primary" href="/">
              トップに戻る
            </Button>
          </div>
        </Section>
      </Layout>
    </NoSsr>
  );
};

export default NotFoundPage;
