import * as React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

import Header from "components/uikit/Header/Header.js";
import Footer from "components/uikit/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/mainPage.js";

const useStyles = makeStyles(styles);
type Props = {
  children: React.ReactNode;
};

export default function Common(props: Props) {
  const { children } = props;
  const classes = useStyles();
  return (
    <>
      <Header
        color="white"
        brand="ONLINE BOAT SHOW"
        fixed
        changeColorOnScroll={{
          height: 0,
          color: "dark",
        }}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div
          className={classes.container}
          style={{ paddingTop: 80, minHeight: "80vh" }}
        >
          <div>{children}</div>
        </div>
      </div>
      <Footer />
    </>
  );
}
